import { confirm } from "./popups.js"

const init = function() {

	const wishlistOverlay = $('#popupWishlistOverlay');
	if (!wishlistOverlay.length) {
		return;
	}

	// close
	wishlistOverlay.find('.js-close').on('click', function() {
		wishlistOverlay.hide();
		return false;
	});

	$('.js-add-to-wishlist:not(.js-add-to-wishlist-done)').each(function() {
		const link = $(this);
		link.on('click', function() {
			addListener(link, wishlistOverlay);
		});

		link.addClass('js-add-to-wishlist-done');
	});
}

const addListener = function(link, wishlistOverlay) {

	const form = link.closest('form');
	link.addClass('loading').attr('disabled', 'disabled');

	// ajax
	$.ajax({
		url: '/wishlist/addremoveajax',
		type: 'POST',
		data: link.is('.js-wishlist-search') ? 'accomId=' + link.attr('data-id') : form.serialize(),
		success: function(response) {
			try {
				response = $.parseJSON(response);
			}
			catch (e) {
				console.log('Error: Could not parseJSON for wishlist overlay: wishlist-overlay.js')
				return;
			}

			if (response.Success) {
				// update count
				const wishlistIconSelector = $('.wishlist-icon').find("i");
				wishlistIconSelector.css("font-weight", response.Count > 0 ? 900 : 400);

				update(response.Title, response.Status == 'Added' ? 'was added to' : 'was removed from', wishlistOverlay);

				if (response.Status === 'Added') {
					link.addClass('added');
				}
				else {
					link.removeClass('added');
				}

				$.ajax({
					url: '/wishlist/ReturnWishlistRollover',
					type: 'POST',
					data: {
						wishlistBannerItems: response.WishlistBannerItems
					},
					success: function(response) {
						$('#jsWishlistRollover').html(response);
					}
				})
			}
			else {
				confirm('Wishlist', response.Message, '', true);
			}

			// change text
			link.removeClass('loading').removeAttr('disabled');
		},
		error: function() {
			// reload page
			confirm('Error', 'Sorry, something went wrong. Please try again, or contact us if the problem continues.', '', true);
			location.reload();
		}
	});

	return false;
}

const update = function(title, action, wishlistOverlay) {

	// update message
	wishlistOverlay.find('.js-title').html(title);
	wishlistOverlay.find('.js-action').html(action);

	// pulse if already visible
	if (wishlistOverlay.is(':visible')) {
		wishlistOverlay.addClass('pulse');
	}

	// clear pulse after ends
	wishlistOverlay.one('animationend', function() {
		wishlistOverlay.removeClass('pulse');
	});

	wishlistOverlay.show();
}

export default init;
